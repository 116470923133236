import Lines from "./components/Lines";
import Banner from "./components/Banner";
import { PageProvider } from "./context/PageContext";

const Home = () => {
    return (
        <>
            <Banner/>
            <PageProvider>
                <Lines/>
            </PageProvider>
        </>
    )
}

export default Home;
