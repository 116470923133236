import { useContext, useEffect, useState } from "react";
import { PageContext } from "../context/PageContext";
import { getTotalSupply, getTokenUri } from "../network/ethereum";

const MAX_LINES_PER_PAGE = 5;
export const useContract = () => {
    const { pageNumber, setPageNumber } = useContext(PageContext);
    const [loading, setLoading] = useState(false);
    const [totalSupply, setTotalSupply] = useState(0);
    const [tokenMetadataList, setTokenMetadataList] = useState([]);
    // const { page } = useContext(pageContext);

    const b64DecodeUnicode = (str) => {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str, 'base64').split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }
    
    useEffect(() => {
        const fetchTokenMetadata = async (tokenId) => {
            const tokenUri = await getTokenUri(tokenId);
            const json = JSON.parse(b64DecodeUnicode(
                tokenUri.replace('data:application/json;base64,', '')).replace(/\\/g,""));
            return json
        }

        const fetchTokenList = async () => {
            setTotalSupply(0);
            setTokenMetadataList([]);

            const fetchedTotalSupply= await getTotalSupply();
            setTotalSupply(fetchedTotalSupply);

            let tml = [];
            let min = parseInt(pageNumber)*MAX_LINES_PER_PAGE;
            let max = Math.min((parseInt(pageNumber) + 1) * MAX_LINES_PER_PAGE, Math.max(totalSupply, MAX_LINES_PER_PAGE));
            for (var tokenId = min; tokenId < max; tokenId++) {
                tml.push(await fetchTokenMetadata(tokenId));
            }
            setTokenMetadataList(tml);
            setLoading(false);
        }
        fetchTokenList();
        if (tokenMetadataList.length !== 0) {
            setLoading(true);
        }
    }, [pageNumber]);

    return { totalSupply, tokenMetadataList, loading };
}