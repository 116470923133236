// context/cart-context.js
import React, { useState, createContext } from "react";

const PageContext = createContext();

const PageProvider = ({ children }) => {
  const [pageNumber, setPageNumber] = useState(0);

  // Public API of the Context
  const value = { pageNumber, setPageNumber };

  return <PageContext.Provider value={value}>{ children }</PageContext.Provider>;
};

export { PageContext, PageProvider };