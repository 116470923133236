import { useContext } from 'react';
import { PageContext } from '../context/PageContext';

export const PageSlider = () => {
    const { pageNumber, setPageNumber } = useContext(PageContext);
    function handlePageChange(event) {
        setPageNumber(event.target.value);
    }
    return (
        <div className="slidecontainer">
        <input 
            type={"range"}
            min="0"
            max="99"
            value={pageNumber}
            className="slider"
            id="nav"
            onChange={handlePageChange}
        ></input>
        <output className="output">{pageNumber}</output>
        </div>
    )
}
