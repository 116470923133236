import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider'

import abi from '../contracts/abi.json';

// const rpcURL =  "https://cloudflare-eth.com";
const rpcURL = "https://eth-mainnet.alchemyapi.io/v2/P6OsARN80T7gw2is0-0cIXKdsmvFG6_Z";
export const contractAddress = "0x43ac571b097bdd7bb74c17aed608c29e08d4de4d";
/**
 * HUGE SHAME
 * export const contractAddress = "0xef8d6c4db043902a648dc162214e565c63a71160";
 */

/**
 * Rinkeby Test Network
 * export const contractAddress = "0x6bcc31e140270f6b5363bdfd60fe661e48f31ddd";
 */ 

const web3 = new Web3(rpcURL);
const contract = new web3.eth.Contract(abi, contractAddress);
var provider = null;

async function ensureProvider() {
    if (provider == null) {
        provider = await detectEthereumProvider();
    }
    return
}
async function connectWallet() {
    try {
        // Request account access
        await ensureProvider();
        await provider.request({ method: 'eth_requestAccounts' });
    } catch (error) {
        // User denied account access...
        console.error("User denied account access")
    }
}

export const getTotalSupply = async () => {
    return await contract.methods.totalSupply().call();
}

export const getMaxSupply = async () => {
    return await contract.methods.MAX_SUPPLY().call();
}

export const buyNFT = async () => {
    await connectWallet();
    let text = prompt("Set the text for your line:", "");
    if (text != null) {
        let textBytes = (new TextEncoder().encode(text)).length
        if (textBytes > 54) {alert("Text too long, try again!"); return;}
        const transactionParams = {
            to: contractAddress,
            from: provider.selectedAddress,
            value: Web3.utils.toHex(Web3.utils.toWei('0.0375', 'ether')),
            // gasLimit: web3.utils.toHex(300000),
            // gasPrice: web3.utils.toHex(web3.utils.toWei('350', 'gwei')),
            data: contract.methods.safeMint(text, 1).encodeABI()
        };
        return await provider.request({
            method: 'eth_sendTransaction',
            params: [transactionParams]
        })  
    }
}

export const getTokenUri = async (tokenId) => {
    return await contract.methods.tokenURI(tokenId).call();
}
