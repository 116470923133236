import React from 'react';
import { useContract } from "../hooks/useContract";
import { contractAddress } from '../network/ethereum';
import { Spinner } from "react-bootstrap";
import Mint from "./Mint";
import { PageSlider } from "./PageSlider";
// const { soliditySha3 } = require("web3-utils");

const Lines = () => {
    const { totalSupply, tokenMetadataList, loading } = useContract();
    return (
        <div id="container">
            {loading &&
                <div className="loading">
                    Loading...
                    <Spinner animation="grow" variant="warning" />
                </div>
            }
            {/* {totalSupply && totalSupply > 0 && */}
            { tokenMetadataList.map((tokenMetadata) => (
                <div style={{flex:1, flexDirection:'column'}} key={tokenMetadata.name}>
                    <a 
                        href={`https://opensea.io/assets/${contractAddress}/${tokenMetadata.name.match(/\d+/)}`}
                        target="_blank"
                        rel="noreferrer">
                    <img
                        key={tokenMetadata.name}
                        src={tokenMetadata.image}
                        alt={tokenMetadata.name}
                        className="segment"
                    /></a>
                    <p className='caption'>{
                        tokenMetadata.attributes.text.trim() !== "" ?
                        tokenMetadata.attributes.text: tokenMetadata.name
                    }</p>
                </div>
            ))}
            <PageSlider />
            <Mint />
        </div>
    )
}

export default Lines;


    // function getImage(colors) { // non-payable
    //     baseURL = "data:image/svg+xml;base64,";
    //     svgEncoded = Base64.encode(bytes(string(abi.encodePacked(
    //         "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"540\" height=\"540\">",
    //         "<rect fill=\"rgba(255,255,255,1)\" height=\"540\" width=\"540\" y=\"0\" x=\"0\"/>",
    //         abi.encodePacked("<rect fill=\"rgba(", colors[0][0],",",colors[0][1],",",colors[0][2],",0.",colors[0][3],
    //                         ")\" height=\"270\" width=\"540\" y=\"0\" x=\"0\"/>"),
    //         abi.encodePacked("<rect fill=\"rgba(", colors[1][0],",",colors[1][1],",",colors[1][2],",0.",colors[1][3],
    //                         ")\" height=\"270\" width=\"540\" y=\"270\" x=\"0\"/>"),
    //         abi.encodePacked("<rect fill=\"rgba(", colors[2][0],",",colors[2][1],",",colors[2][2],",1",
    //                         ")\" height=\"81\" width=\"540\" y=\"229.5\" x=\"0\"/></svg>")
    //     ))));
    //     return string(abi.encodePacked(baseURL, svgEncoded));
    //     }

    // function getColors(_tokenId) {
    // return [
    //     [
    //         randMod(  0, 255, _tokenId, "001"),
    //         randMod(100, 210, _tokenId, "002"),
    //         randMod(  0, 255, _tokenId, "003"),
    //         randMod(600, 999, _tokenId, "004")],
    //     [
    //         randMod(  0, 255, _tokenId, "010"),
    //         randMod(100, 210, _tokenId, "020"),
    //         randMod(  0, 255, _tokenId, "030"),
    //         randMod(850, 999, _tokenId, "040")],
    //     [
    //         randMod(  0,  95, _tokenId, "000"), 
    //         randMod(  0,  27, _tokenId, "100"),
    //         randMod(  0, 100, _tokenId, "200"),
    //         "000"]];
    // }

    // function randMod(min, max, tokenId, salt) {
    //     return Strings.toString(min + (uint256(keccak256(abi.encodePacked(salt,"786",tokenId))) % (max-min)));
    // }
