import { buyNFT } from "../network/ethereum";

const Mint = () => {
    return (
        <div className="buttons">
            <button
                id="mint"
                onClick={buyNFT}
            > !Mint
            </button>
        </div>
    )
}

export default Mint;